import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';  // Import CommonModule


import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeroComponent } from './components/hero/hero.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { SignupEmployerComponent } from './signup-employer/signup-employer.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PlatformSubscriptionAgreementComponent } from './platform-subscription-agreement/platform-subscription-agreement.component';
import { SignupPaymentDetailsComponent } from './signup-payment-details/signup-payment-details.component';
import { SignupDoneComponent } from './signup-done/signup-done.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ConfirmationCodeDialogComponent } from './confirmation-code-dialog/confirmation-code-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorHandlerService } from './error-handler-service.service';
import { AppInsightsLogginService } from './logging.service';
import { CancelSubscriptionComponent } from './pages/cancel-subscription/cancel-subscription.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { ChangeSubscriptionComponent } from './pages/change-subscription/change-subscription.component';
import { BuySubscriptionComponent } from './pages/buy-subscription/buy-subscription.component';
import { SortContactsComponent } from './pages/sort-contacts/sort-contacts.component';
import { AcceptTermsComponent } from './pages/accept-terms/accept-terms.component';
import { SignupWorkerComponent } from './signup-worker/signup-worker.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { SignupWorkerDoneComponent } from './signup-worker-done/signup-worker-done.component';
import { HousingContactFormComponent } from './pages/housing-contact-form/housing-contact-form.component';
import { ResourcesIndexComponent } from './pages/resources/index/index.component';
import { ResourceDetailComponent } from './pages/resources/resource-detail/resource-detail.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NavBarComponent,
    FooterComponent,
    HeroComponent,
    HomeContentComponent,
    LoadingComponent,
    ErrorComponent,
    SignupEmployerComponent,
    TermsOfServiceComponent,
    PlatformSubscriptionAgreementComponent,
    SignupPaymentDetailsComponent,
    SignupDoneComponent,
    ErrorModalComponent,
    InfoModalComponent,
    ConfirmationCodeDialogComponent,
    CancelSubscriptionComponent,
    ChangeSubscriptionComponent,
    BuySubscriptionComponent,
    SortContactsComponent,
    AcceptTermsComponent,
    SignupWorkerComponent,
    SignupWorkerDoneComponent,
    HousingContactFormComponent,
    ResourcesIndexComponent,
    ResourceDetailComponent,
    VideoPlayerComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HighlightModule,
    FontAwesomeModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatDialogModule,
    MatIconModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatExpansionModule,
    DragDropModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    CommonModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  providers: [
    {
      provide: AppInsightsLogginService, 
      useClass:AppInsightsLogginService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    { 
      provide: ErrorHandler, 
      useClass: ErrorHandlerService },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
