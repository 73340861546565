import { Component } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { EMPTY, Observable, Subject, catchError, concatMap, filter, forkJoin, from, map, of, startWith, switchMap, take, takeUntil } from 'rxjs';
import { CancelReport, Customer, Invoice, Package } from 'src/app/models/ChargeOverModels';
import { ErrorModalComponent } from 'src/app/error-modal/error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EmployerContactSubscriptionDTO } from 'src/app/models/AccountManagementModels';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';
import { faDownload, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [ trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],
})
export class HomeComponent {
  unsubscribe$ = new Subject<void>();
  customer: Customer;
  package: Package;
  invoices: Invoice[];
  cancelReport: CancelReport
  downgradeReport: CancelReport
  employerContactSubscription: EmployerContactSubscriptionDTO;
  user: User;
  userType: string;
  portalURL: string;
  displayedColumns: string[] = ['invoice_id', 'date', 'due_date', 'invoice_status_name', 'total', 'payments', 'balance', 'actions'];
  isSmallScreen: boolean = false;
  bounceIn: any;
  fadeIn: any;
  faDownload = faDownload;
  faCashRegister = faCashRegister;
  chargeoverProfileErrorOpened: boolean = false;
  constructor(public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver) { }


  ngOnInit() {
    // adjust the displayed invoice columns based on screen size
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        this.displayedColumns = ['invoice_id', 'invoice_status_name', 'balance', 'actions'];
      } else if (result.breakpoints[Breakpoints.Small]) {
        this.displayedColumns = ['invoice_id','due_date', 'invoice_status_name','total', 'balance', 'actions'];
      } else {
        this.displayedColumns = ['invoice_id', 'date', 'due_date', 'invoice_status_name', 'total', 'payments', 'balance', 'actions'];
      }
    });

    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),

        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          const employerContactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];
          this.portalURL = this.sharedDataService.getData('portalURL');
          if (this.userType === 'E') {
            return this.apiService.getPrimaryEmployerForContact(employerContactId);
          }
          if (this.userType === 'S') {
            console.log(this.userType);
          this.portalURL = this.sharedDataService.getData('portalURL');
            if(!this.portalURL)    {
              this.apiService.GetConfigurationParam("PortalURL").subscribe(res => { 
                this.portalURL = res; 
                this.sharedDataService.setData<string>('portalURL', res);
                window.location.href = this.portalURL;
              });
            }
            else{
              window.location.href = this.portalURL;
            }
          }
          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),

        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {

        this.employerContactSubscription = employerContactSubscription;

        this.apiService.getChargeoverProfile(this.employerContactSubscription.employer.employerId)
        .pipe(
          concatMap(customer => {
            this.customer = customer;
      
            // Handle getChargeoverSubscription separately
            return this.apiService.getChargeoverSubscription(this.employerContactSubscription.employer.employerId).pipe(
              catchError(subscriptionError => {
                // Custom error handling for getChargeoverSubscription errors
                console.error('Subscription error:', subscriptionError);
                if(subscriptionError.error.errorCode === 2)
                {
                  this.router.navigate(['/buy-subscription'])
                }
                else
                {
                  this.openErrorModal(subscriptionError);
                }
                return EMPTY; 
              }),
              concatMap(subscription => {
                // If subscription call succeeds, then proceed with forkJoin for other calls
                return forkJoin({
                  subscription: of(subscription), // Wrap the successful result to be used in forkJoin
                  invoices: this.apiService.getAllChargeoverInvoices(this.employerContactSubscription.employer.employerId),
                  cancelReport: this.apiService.GetCancelReport(117, this.employerContactSubscription.employer.employerId),
                  downgradeReport: this.apiService.GetDowngradePendingReport(this.employerContactSubscription.employer.employerId),
                });
              }),
            );
          }),
          map(res => {
            // Assuming res is now an object with keys due to the updated forkJoin usage above
            this.package = res.subscription as Package;
            this.invoices = res.invoices as Invoice[];
            this.invoices = this.invoices.filter(invoice => invoice.total > 0.01);
            this.cancelReport = res.cancelReport as CancelReport;
            this.downgradeReport = res.downgradeReport as CancelReport
          }),
          catchError(e => {
            // This will handle errors from getChargeoverProfile and the subsequent forkJoin if any
            console.error('Profile or forkJoin error:', e);
            if (e.error && e.error.errorCode == 2) { // subscription not found
              this.openErrorModal(e, true);
            } else {
              this.openErrorModal(e);
            }
            return EMPTY;
          }),
        ).subscribe();
      
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openErrorModal(error: any, redirectToPaymentDetails: boolean = false): void {
    if(!this.chargeoverProfileErrorOpened)
    {
      this.chargeoverProfileErrorOpened == true;
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: error ,
        maxWidth: '70vw', // specify max width
        maxHeight: '80vh', // specify max height
        //height: '80vh' // specify height
      });
  
    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      this.chargeoverProfileErrorOpened = false;
      if(redirectToPaymentDetails)
      {
        console.log("redirectToPaymentDetails");
        this.router.navigate(['/signup-payment-details'], { 
          queryParams: { 
            Id: this.employerContactSubscription.employer.employerId 
          } 
        });
      }
    });
  }
  }
  openInfoModal(message: string): void {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: { message: message },
      maxWidth: '70vw', 
      maxHeight: '80vh',
    });   
  
    dialogRef.afterClosed().subscribe(() => {

    });
  }

  getCustomerApprovalStatus(statusId: number): string {
    switch (statusId) {
      case 1:
        return 'Approved';
      case 2:
        return 'Pending Approval';
      case 3:
        return 'Rejected';
      default:
        return 'Unknown';
    }
  }
  getSubscriptionStatus(status: string): string {
    switch (status) {
      case 'active-trial':
      case 'active-current':
        return 'Active';
      case 'active-overdue':
      case 'canceled-nonpayment':
      case 'canceled-manual':
      case 'expired-expired':
      case 'expired-trial':
      case 'suspended-suspended':
        return 'Inactive';
      default:
        return 'Unknown';
    }
  }

  navigateTo(url: string) {
    window.open(url, '_blank');
  }

  openInfoMessageDialog()
  {
    this.openInfoModal("We are working to roll this out in the coming weeks.  In the meantime, please email us at info@seasonalconnect.com for any changes you would like to make to your plan.");
  }

}
