import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { bounceIn, fadeIn } from 'ng-animate';
import { transition, trigger, useAnimation } from '@angular/animations';
import { AppInsightsLogginService } from '../../logging.service';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { EMPTY, filter, switchMap } from 'rxjs';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  animations: [ trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class NavBarComponent implements OnInit{
  @ViewChild('sidenav') sidenav: MatSidenav;
  isCollapsed = true;
  faUser = faUser;
  faPowerOff = faPowerOff;
  portalURL: string;
  fadeIn: any;
  userType: string;


  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
    private appInsights: AppInsightsLogginService,
    private location: Location,
  ) {
    
  }


  // Function to toggle the sidenav


  ngOnInit() {
    this.appInsights.logTrace(this.location.path());
    this.portalURL = this.sharedDataService.getData('portalURL');
    if(!this.portalURL)    {
      this.apiService.GetConfigurationParam("PortalURL").subscribe(res => { 
        this.portalURL = res; 
        this.sharedDataService.setData<string>('portalURL', res);
      });
    }

    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),
        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          this.userType = arrMeta[0];

          console.log(this.userType);

          return EMPTY;
        })
      ).subscribe(); // <- Add this subscribe call
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  navigateTo(url: string): void {
    window.location.href = url;
}

  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
}
