<div class="container" *ngIf="isLoading">
    <app-loading></app-loading>
</div>
<form *ngIf="!isLoading" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
    <mat-card class="">
        <div class="signup-employer-mat-card">
            <h1 class="center-title-join"><span class="center-title-span">Join the </span>Seasonal Connect Community
            </h1>

            <mat-card-content>
                <div formGroupName="section1">
                    <!-- Section 1: Email & Password -->
                    <mat-card-title>Email & Password</mat-card-title>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input outlined matInput placeholder="Email*" formControlName="email">
                            <mat-error *ngIf="signupForm.get('section1').get('email').hasError('required')">
                                Email is required
                            </mat-error>
                            <mat-error *ngIf="signupForm.get('section1').get('email').hasError('email')">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic">
                            <mat-label>Password</mat-label>
                            <input matInput [type]="showPassword ? 'text' : 'password'" name="password"
                                formControlName="password">
                            <mat-icon matSuffix (click)="togglePasswordVisibility()">
                                {{showPassword?'visibility_off':'visibility'}}
                            </mat-icon>
                            <mat-error *ngIf="signupForm.get('section1').get('password').hasError('required')">
                                Password is required
                            </mat-error>
                            <mat-error *ngIf="isPasswordError"
                                [@expandCollapse]="isPasswordError ? 'expanded' : 'collapsed'"
                                style="display: inline-block;">
                                Password must be at least 6 characters and include an uppercase letter, a lowercase
                                letter, a number and a special character - !&#64;#%^&$*_+-~.,
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <!-- Section 2: Company Details -->

                <div formGroupName="section2" class="company-details">
                    <mat-card-title>Company & Contact Details</mat-card-title>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline">
                            <mat-label>Legal Company Name</mat-label>
                            <input matInput placeholder="Legal Company Name*" formControlName="companyName">
                            <mat-error *ngIf="signupForm.get('section2').get('companyName').hasError('required')">
                                Legal Company Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-label>Busy Season</mat-label>
                        </div>
                        <div class="radio-group-container">
                            <mat-radio-group formControlName="season" aria-label="Select a season">
                                <mat-radio-button value="Summer">Summer</mat-radio-button>
                                <mat-radio-button value="Winter">Winter</mat-radio-button>
                                <mat-radio-button value="Both">Both</mat-radio-button>
                            </mat-radio-group>
                        </div>
                                                           
                        
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-error *ngIf="signupForm.get('section2').get('season').hasError('required') && signupForm.get('section2').get('season').touched">
                                Season is required
                            </mat-error>
                        </div>
                    </div>
                   
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline" fxFlex="50" subscriptSizing="dynamic">
                            <mat-label>Contact First Name</mat-label>
                            <input matInput placeholder="Contact First Name*" formControlName="firstName">
                            <mat-error *ngIf="signupForm.get('section2').get('firstName').hasError('required')">
                                Contact First Name is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" subscriptSizing="dynamic">
                            <mat-label>Contact Last Name</mat-label>
                            <input matInput placeholder="Contact Last Name*" formControlName="lastName">
                            <mat-error *ngIf="signupForm.get('section2').get('lastName').hasError('required')">
                                Contact Last Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>City</mat-label>
                            <input matInput placeholder="City*" formControlName="city">
                            <mat-error *ngIf="signupForm.get('section2').get('city').hasError('required')">
                                City is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" subscriptSizing="dynamic">
                            <mat-label>State</mat-label>
                            <input type="text" placeholder="Enter your state" aria-label="State" matInput
                                [formControl]="stateCtrl" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let state of filteredStates | async" [value]="state">
                                    {{state}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="isStateError"
                            [@expandCollapse]="isStateError ? 'expanded' : 'collapsed'"
                            style="display: inline-block;">
                            Please select the state from the dropdown menu.
                        </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Street</mat-label>
                            <input matInput placeholder="Street*" formControlName="street">
                            <mat-error *ngIf="signupForm.get('section2').get('street').hasError('required')">
                                Street is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" subscriptSizing="dynamic">
                            <mat-label>ZIP</mat-label>
                            <input matInput placeholder="ZIP*" formControlName="zip">
                            <mat-error *ngIf="signupForm.get('section2').get('zip').hasError('required')">
                                ZIP is required
                            </mat-error>
                            <mat-error *ngIf="signupForm.get('section2').get('zip').hasError('zipCode')">
                                Invalid ZIP Code
                              </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div formGroupName="section3">
                    <mat-card class="my-card">
                        <mat-card-header>
                            <mat-card-title>Terms and Conditions</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="form-field">
                                <mat-checkbox color="accent" formControlName="termsOfService">
                                    I agree to the Terms of Service
                                </mat-checkbox>
                                <button type="button" (click)="openTermsOfService()" mat-icon-button
                                    aria-label="Terms of Service">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                                <mat-error *ngIf="signupForm.get('section3').get('termsOfService').touched && 
                                        signupForm.get('section3').get('termsOfService').hasError('required')">
                                    You have to agree to the Terms of Service
                                </mat-error>
                            </div>
                            <div class="form-field">
                                <mat-checkbox formControlName="platformSubscriptionAgreement" color="accent">
                                    I agree to the Subscription Agreement
                                </mat-checkbox>
                                <button type="button" (click)="openPlatformSubscriptionAgreement()" mat-icon-button
                                    aria-label="Platform Subscription Agreement">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                                <mat-error
                                    *ngIf="signupForm.get('section3').get('platformSubscriptionAgreement').touched && 
                                        signupForm.get('section3').get('platformSubscriptionAgreement').hasError('required')">
                                    You have to agree to the Platform Subscription Agreement
                                </mat-error>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>


            </mat-card-content>

            <mat-card-actions>
                <button mat-raised-button class="signup-button" color="primary" type="submit">Sign Up</button>
            </mat-card-actions>
        </div>
    </mat-card>
</form>