import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfServiceComponent } from '../terms-of-service/terms-of-service.component';
import { PlatformSubscriptionAgreementComponent } from '../platform-subscription-agreement/platform-subscription-agreement.component';
import { Observable, map, startWith } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from 'src/app/api.service';
import { CreateEmployerModel, Employer } from '../models/SignupModels';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { SharedDataService } from '../shared-data-service.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';



@Component({
  selector: 'app-signup-employer',
  templateUrl: './signup-employer.component.html',
  styleUrls: ['./signup-employer.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '0px', minHeight: '0', marginBottom: '0' })),
      state('expanded', style({ height: '*', marginBottom: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ])
  ],

})

export class SignupEmployerComponent implements OnInit {
  public showPassword: boolean = false;
  signupForm: FormGroup;
  panelOpenState = false;  
  plan = "Basic";
  yearly = false;
  isLoading = false;
  networkId: number;
  couponCode: string;

  states: string[] = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
  stateCtrl = new FormControl('', [Validators.required, (fc: FormControl) => this.stateValidator(fc)]);
  filteredStates: Observable<string[]>;

  constructor(private formBuilder: FormBuilder, 
    public dialog: MatDialog, 
    private api: ApiService, 
    private router: Router,
    private sharedDataService: SharedDataService, 
    private route: ActivatedRoute ) {

      this.route.queryParams.subscribe(params => {
        this.plan = params['Plan'] || 'Basic';
        this.yearly = params['Yearly'] === 'true';   
        this.networkId =params['Network'] || 0;
        this.couponCode = params['CouponCode'] || '';

        this.signupForm = this.formBuilder.group({
          section1: this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, this.passwordValidator]],
          }),
          section2: this.formBuilder.group({
            companyName: ['', Validators.required],
            season: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            city: ['', Validators.required],
            street: ['', Validators.required],
            zip: ['', [Validators.required, zipCodeValidator()]],
          }),
          section3: this.formBuilder.group({
            termsOfService: [false, Validators.requiredTrue],
            platformSubscriptionAgreement: [false, Validators.requiredTrue],
          })
        });
      });
  }

  ngOnInit(): void {
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => this._filterStates(state || '')),
      );
  }

  onSubmit(): void {
    this.sharedDataService.clearStorage();
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched(); // mark fields as touched to show errors
    } else {
      const createEmployerModel: CreateEmployerModel = {
        email: this.signupForm.get('section1').get('email').value,
        password: this.signupForm.get('section1').get('password').value,
        companyName: this.signupForm.get('section2').get('companyName').value,
        firstName: this.signupForm.get('section2').get('firstName').value,
        lastName: this.signupForm.get('section2').get('lastName').value,
        city: this.signupForm.get('section2').get('city').value,
        state: this.stateCtrl.value,
        street: this.signupForm.get('section2').get('street').value,
        zip: this.signupForm.get('section2').get('zip').value,
        plan: this.plan,
        yearly: this.yearly,
        termsOfService: this.signupForm.get('section3').get('termsOfService').value,
        platformSubscriptionAgreement: this.signupForm.get('section3').get('platformSubscriptionAgreement').value,
        employerId: 0,
        season: this.signupForm.get('section2').get('season').value,
        networkId: this.networkId,
        couponCode: this.couponCode
      };
      this.isLoading = true;
      this.api.createEmployer(createEmployerModel).subscribe(response => {
        createEmployerModel.employerId = response.employerId;
        this.sharedDataService.setData<CreateEmployerModel>('employer', createEmployerModel);
        this.isLoading = false;
        this.router.navigate(['/signup-payment-details']);
      }, error => {
        this.isLoading = false;
        this.openErrorModal(error);
      });
    }
  }
  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter(state => state.toLowerCase().includes(filterValue));
  }

  get isPasswordError(): boolean {
    return this.signupForm.get('section1').get('password').hasError('passwordValidator');
  }

  get isStateError(): boolean {
    return this.stateCtrl.hasError('stateValidator');
  }
  openTermsOfService(): void {
    const dialogRef = this.dialog.open(TermsOfServiceComponent, {
      maxWidth: '70vw',  // specify max width
      maxHeight: '80vh',  // specify max height
      height: '80vh',  // specify height
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Set the 'termsOfService' form control value to true
        this.signupForm.get('section3').get('termsOfService').setValue(true);
      }
    });
  }
  openPlatformSubscriptionAgreement(): void {
    const firstNameCtrl = this.signupForm.get('section2').get('firstName');
    const lastNameCtrl = this.signupForm.get('section2').get('lastName');
    const companyNameCtrl = this.signupForm.get('section2').get('companyName');
    const cityCtrl = this.signupForm.get('section2').get('city');
    if (
      !firstNameCtrl.valid ||
      !lastNameCtrl.valid ||
      !companyNameCtrl.valid ||
      !cityCtrl.valid ||
      !this.stateCtrl.valid
    ) {
      // Mark only those fields as touched
      firstNameCtrl.markAsTouched();
      lastNameCtrl.markAsTouched();
      companyNameCtrl.markAsTouched();
      cityCtrl.markAsTouched();
      this.stateCtrl.markAsTouched();
      
      return;
    }


    const dialogRef = this.dialog.open(PlatformSubscriptionAgreementComponent, {
      data: { 
        signupForm: this.signupForm, 
        stateCtrl: this.stateCtrl?.value  // Pass the stateCtrl here
      }, 
      maxWidth: '70vw',  
      maxHeight: '80vh',  
      height: '80vh',  
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Set the 'termsOfService' form control value to true
        this.signupForm.get('section3').get('platformSubscriptionAgreement').setValue(true);
      }
    });
  }
  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }
  passwordValidator(formControl: FormControl) {
    const password = formControl.value;
    const hasNumber = /\d/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecial = /[!@#%^&$*()_+\-=[\]{}|;':"\\,.<>/?~`]/.test(password);
    const lengthValid = password.length >= 8 ? true : false;
    const valid = hasNumber && hasLowercase && hasUppercase && lengthValid && hasSpecial;
    if (!valid) {
      // return what´s not valid
      return { passwordValidator: true };
    }
    return null;
  }
  stateValidator(formControl: FormControl) {
    formControl.value
    const valid = this.states.includes(formControl.value);
    if (!valid) {
      // return what´s not valid
      return { stateValidator: true };
    }
    return null;
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }  

}
export function zipCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const valid = /^[0-9]{5}$/.test(value);
    return valid ? null : { zipCode: true };
  };
}