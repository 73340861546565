import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedDataService } from '../shared-data-service.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CreateEmployerModel, CreditCard, Employer, Network, PaymentDetails, PlanPriceResponse } from '../models/SignupModels';
import { EMPTY, Observable, Subject, catchError, from, map, startWith, switchMap, take, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ChargeOverService } from '../chargeover.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ConfirmationCodeDialogComponent } from '../confirmation-code-dialog/confirmation-code-dialog.component';
import { AuthService } from '@auth0/auth0-angular';


@Component({
  selector: 'app-signup-payment-details',
  templateUrl: './signup-payment-details.component.html',
  styleUrls: ['./signup-payment-details.component.css']
})
export class SignupPaymentDetailsComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  plans = [
    { value: 'Basic', viewValue: 'Basic' },
    { value: 'Advanced', viewValue: 'Advanced' },
    { value: 'Premium', viewValue: 'Premium' },
  ];
  employerData: CreateEmployerModel;
  employerId: string;
  confirmationCode: string;
  amountToPay = 99.99;
  filteredEmployers: Observable<Employer[]>;
  employerCtrl = new FormControl();
  creditCard: any;
  paymentForm: FormGroup;
  months = Array.from({ length: 12 }, (_, i) => i + 1);
  years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() + i);
  isLoading = true;
  confirmationCodePlaceholder = "Check your email for Confirmation Code";
  isPriceLoading: boolean = false;
  planPriceResponse: PlanPriceResponse;
  isCreditCardFieldVisible = true;
  networkId = 0;
  networkName: string;

  constructor(
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private chargeOverService: ChargeOverService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    public auth: AuthService,
  ) {
    this.employerData = this.sharedDataService.getData('employer');
    this.paymentForm = this.fb.group({
      confirmationCode: ['', [Validators.required]],
      // Credit Card Fields
      creditCardName: [''],
      cardNumber: [''],
      expMonth: [''],
      expYear: [''],
      cvv: [''],
      // Coupon Code
      couponCode: [''],
      yearly: [false],
      plan: ['', Validators.required],
    }, { validators: [] });

    this.addCreditCardValidators();

  }

  ngOnInit(): void {
    this.auth.user$;
    this.route.queryParams.subscribe(params => {
      this.employerId = params['Id'] || '';
      this.confirmationCode = params['ConfirmationCode'] || '';

      const isEmployerDataEmpty = !this.employerData;
      const isEmployerIdNonEmpty = this.employerId !== '';
      const doIdsMatch = this.employerId === ((this.employerData?.employerId || '') as string);

      if (isEmployerDataEmpty && !isEmployerIdNonEmpty) {
        this.router.navigate(['/signup-employer']);
      }

      if ((isEmployerDataEmpty || !doIdsMatch) && isEmployerIdNonEmpty) {
        this.isLoading = true;
        this.apiService.getCompanyDetails(this.employerId, this.confirmationCode)
          .pipe(
            switchMap(data => {
              this.employerData = data;
              if (data.couponCode) {
                this.paymentForm.get('couponCode').setValue(data.couponCode);
              }
              if (data.networkId > 0) {
                this.networkId = data.networkId
                this.getNetworkName();
              }
              this.sharedDataService.setData<CreateEmployerModel>('employer', data);
              this.calculatePrice();
              this.setInitialData();
              this.isLoading = false;  // Reset the loading flag
              return EMPTY;
            }),
            catchError(e => {
              this.isLoading = false; // Stop loading animation or indicator
              console.error(e);
              this.openErrorModal(e);
              this.router.navigate(['/signup-employer']);
              return EMPTY;
            }),
            takeUntil(this.unsubscribe$)
          )
          .subscribe();
      } else {
        if (this.employerData.couponCode) {
          this.paymentForm.get('couponCode').setValue(this.employerData.couponCode);
        }
        if (this.employerData.networkId > 0) {
          this.networkId = this.employerData.networkId
          this.getNetworkName();
        }
        this.isLoading = false;
        this.calculatePrice();
        this.setInitialData()
      }
    });
  }
  onSubmit() {
    if (!this.paymentForm.valid) {
      this.paymentForm.markAllAsTouched();
      console.error('Form is not valid');
      return;
    }
    this.employerData.plan = this.paymentForm.get('plan').value;
    this.employerData.yearly = this.paymentForm.get('yearly').value;
    this.updatePlanName();
    this.buildCreditCardObject();

    this.isLoading = true;

    this.apiService.checkConfirmationCode(
      this.paymentForm.get('confirmationCode').value,
      this.employerData.employerId.toString()
    )
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => from(this.chargeOverService.signupCreditCardCall(this.employerData, this.creditCard, this.paymentForm.get('couponCode').value || '')))
      )
      .subscribe({
        next: (result) => {
          this.router.navigate(['/signup-done']);
        },
        error: (e) => {
          this.isLoading = false;
          this.openErrorModal(e);
        }
      });
  }

  updatePlanName() {
    this.employerData.plan = this.employerData.plan.toLowerCase();
    if (this.employerData.yearly && !this.employerData.plan.includes('yearly')) {
      this.employerData.plan += '-yearly';
    }
  }

  buildCreditCardObject() {
    const { creditCardName, cardNumber, expMonth, expYear, cvv } = this.paymentForm.value;
    this.creditCard = {
      name: creditCardName,
      number: cardNumber.split(' ').join(''),
      expdate_month: expMonth,
      expdate_year: expYear,
      cvv: cvv
    };
  }

  calculatePrice() {
    if (this.employerData) {
      this.isPriceLoading = true;
      this.apiService.getPlanPrice(this.employerData.plan, this.employerData.yearly || false, this.paymentForm.get('couponCode').value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => {
          this.planPriceResponse = data as PlanPriceResponse
          this.amountToPay = this.planPriceResponse.price;
          if (this.amountToPay == 0) {
            this.disablePaymentForZeroAmount();
          }
          else {
            this.addCreditCardValidators();
          }
          this.isPriceLoading = false;
          if (!this.planPriceResponse.isSuccess) {
            this.openErrorModal(this.planPriceResponse.message);
          }
        });
    }
  }

  getNetworkName() {
    console.log("networkId", this.networkId)
    this.apiService.getNetwork(this.networkId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: Network) => {
        this.networkName = data.networkName;
      });
  }

  disablePaymentForZeroAmount() {
    this.removeCreditCardValidators();
    
    this.isCreditCardFieldVisible = false;
  }
  removeCreditCardValidators() {
    const ccFields = ['creditCardName', 'cardNumber', 'expMonth', 'expYear', 'cvv'];
    ccFields.forEach(field => {
      const control = this.paymentForm.get(field);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });
    this.paymentForm.get('expMonth').setErrors(null);
    this.paymentForm.get('expYear').setErrors(null);
    this.paymentForm.updateValueAndValidity();
  }

  addCreditCardValidators() {
    const ccFields = ['creditCardName', 'cardNumber', 'expMonth', 'expYear', 'cvv'];
    ccFields.forEach(field => this.paymentForm.get(field).setValidators(this.getFieldValidators(field)));
    this.paymentForm.updateValueAndValidity();
    this.setExpiryDateValidators();
  }

  getFieldValidators(fieldName: string): ValidatorFn[] {
    const validators = {
      creditCardName: [Validators.required],
      cardNumber: [Validators.required, this.lunhValidator.bind(this)],
      expMonth: [Validators.required],
      expYear: [Validators.required],
      cvv: [Validators.required, Validators.pattern('^[0-9]{3,4}$')],
    };
    return validators[fieldName];
  }

  // getInvalidControls() {
  //   const invalidControls = [];
  //   const controls = this.paymentForm.controls;
  //   for (const name in controls) {
  //     if (controls[name].invalid) {
  //       invalidControls.push(name);
  //     }
  //   }
  //   return invalidControls;
  // }

  sendNewConfirmationCode() {
    this.apiService.sendNewConfirmationCode(this.employerData?.employerId ?? Number(this.employerId)).pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }

  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationCodeDialogComponent, {
      data: {
        message: 'Do you want us to send you a new code?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sendNewConfirmationCode();
        // Display your message that code has been sent
      }
    });
  }

  setInitialData() {
    this.paymentForm.get('creditCardName').setValue(this.employerData.firstName + ' ' + this.employerData.lastName);
    if (this.employerData.plan) {
      this.paymentForm.get('plan').setValue(this.employerData.plan);
    }
    this.paymentForm.get('yearly').setValue(this.employerData.yearly);

    this.paymentForm.get('confirmationCode').setValue(this.confirmationCode);

    // this.paymentForm.get('achName').setValue(this.employerData.firstName + ' ' + this.employerData.lastName);
  }


  private setExpiryDateValidators() {
    if (this.isCreditCardFieldVisible) {
      const expMonthControl = this.paymentForm.get('expMonth');
      const expYearControl = this.paymentForm.get('expYear');

      expMonthControl.valueChanges.subscribe(() => {
        this.checkExpiryDate();
      });

      expYearControl.valueChanges.subscribe(() => {
        this.checkExpiryDate();
      });
    }
  }
  yearlyToggleTriggered() {
    this.employerData.yearly = this.paymentForm.get('yearly').value;
    this.calculatePrice();
  }
  planChangeTrigered() {
    this.employerData.plan = this.paymentForm.get('plan').value;
    this.calculatePrice();
  }

  private checkExpiryDate() {
    if (this.isCreditCardFieldVisible) {
      const expMonthControl = this.paymentForm.get('expMonth');
      const expYearControl = this.paymentForm.get('expYear');

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const expMonth = expMonthControl.value;
      const expYear = expYearControl.value;


      if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
        expMonthControl.setErrors({ 'expired': true });
        expYearControl.setErrors({ 'expired': true });
      } else {
        expMonthControl.setErrors(null);
        expYearControl.setErrors(null);
      }
    }
    else {
      this.paymentForm.get('expMonth').setErrors(null);
      this.paymentForm.get('expYear').setErrors(null);
    }
  }
  lunhValidator(control: AbstractControl): ValidationErrors | null {
    let val = control.value.split(' ').join('');
    let sum = 0;
    let shouldDouble = false;
    if (val.length < 12 && val.length > 0) {
      // The card number entered failed the Luhn check.
      return { 'invalidCard': true };
    }

    // We need to work from right to left. This reverses the string.
    val = val.split('').reverse().join('');

    for (let i = 0; i < val.length; i++) {
      let digit = parseInt(val.charAt(i));

      if (shouldDouble) {
        if ((digit *= 2) > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    if (sum % 10 !== 0) {
      // The card number entered failed the Luhn check.
      return { 'invalidCard': true };
    }

    return null;
  }
}