<div class="container" *ngIf="auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <div class="resources">
    <div class="header">
      <h1 (click)="navigateIndex('resources')">Resources</h1>
    </div>
    <div class="icon-title" fxLayout="row">
      <div [ngClass]="['icon-background', iconClass]"></div>
            <fa-icon *ngIf="icon" [icon]="icon" class="icon " ></fa-icon>
    </div>
    <div fxLayout="row" fxLayoutGap="5em" fxLayoutGap.lt-sm="5px" class="title">
      <h2>{{ title }}</h2>
    </div>
    <app-loading *ngIf="loadingResources"></app-loading>

    <div *ngIf="!resources" class="resource-placeholder" >  </div>

      <div *ngIf="resources" class="resource-categories" >
      <app-loading *ngIf="loadingResources"></app-loading>
      <div *ngIf="noResources">No resources available for this topic</div>
      <div class="resource-row" fxLayout="row" fxLayoutGap="5vw" fxLayoutAlign="space-between stretch" fxLayoutGap.lt-sm="5px">
        <div *ngIf="getResourcesByCategory(4).length" fxFlex="50" fxFlex.lt-sm="100" [@fadeIn]="fadeIn" class="resource-column ">
          <h3>Webinars</h3>
          <div class="resource-list">
            <div class="resource-item" *ngFor="let resource of getResourcesByCategory(4)">
              <a (click)="openWebinar(resource)" [attr.data-full-title]="resource.title">{{ resource.title }}</a>
            </div>
          </div>
        </div>
        <div *ngIf="getResourcesByCategory(1).length" fxFlex="50" fxFlex.lt-sm="100" [@fadeIn]="fadeIn" class="resource-column">
          <h3>Documents</h3>
          <div class="resource-list">
            <div class="resource-item" *ngFor="let resource of getResourcesByCategory(1)">
              
              <a [href]="resource.link" [attr.data-full-title]="resource.title">{{ resource.title }} &nbsp;&nbsp; <fa-icon [icon]="faDownload" class="icon-small" ></fa-icon></a>
            </div>
          </div>
        </div>
      </div>
      <div class="resource-row" fxLayout="row" fxLayoutGap="5vw" fxLayoutAlign="space-between stretch" fxLayoutGap.lt-sm="5px">
        <div *ngIf="getResourcesByCategory(2).length" fxFlex="50" fxFlex.lt-sm="100" [@fadeIn]="fadeIn" class="resource-column">
          <h3>Links</h3>
          <div class="resource-list">
            <div class="resource-item" *ngFor="let resource of getResourcesByCategory(2)">
              <a [href]="resource.link"  target="_blank" [attr.data-full-title]="resource.title">{{ resource.title }}</a>
            </div>
          </div>
        </div>
        <div *ngIf="getResourcesByCategory(3).length" fxFlex="50" fxFlex.lt-sm="100" [@fadeIn]="fadeIn" class="resource-column">
          <h3>Videos</h3>
          <div class="resource-list">
            <div class="resource-item" *ngFor="let resource of getResourcesByCategory(3)">
              <a [href]="'video?url='+resource.link"  target="_blank" [attr.data-full-title]="resource.title">{{ resource.title }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="resources">

  <div class="resources-grid"  [@fadeIn]="fadeIn">
    <div class="resource-box" (click)="navigate('1')">
      <div class="icon-container">
        <div class="icon-background recruitment"></div>
        <fa-icon [icon]="faUsers" class="icon outlined"></fa-icon>
      </div>
      <span>Recruitment</span>
    </div>
    <div class="resource-box" (click)="navigate('2')">
      <div class="icon-container">
        <div class="icon-background staff-management rotate-90 "></div>
        <fa-icon [icon]="faUsersGear" class="icon outlined"></fa-icon>
      </div>
      <span>Staff Management</span>
    </div>
    <div class="resource-box" (click)="navigate('3')">
      <div class="icon-container">
        <div class="icon-background housing-transportation rotate-45"></div>
        <fa-icon [icon]="faPeopleRoof" class="icon outlined"></fa-icon>
      </div>
      <span>Housing & Transportation</span>
    </div>
    <div class="resource-box" (click)="navigate('4')">
      <div class="icon-container">
        <div class="icon-background immigration rotate-130"></div>
        <fa-icon [icon]="faEarthAmericas" class="icon outlined"></fa-icon>
      </div>
      <span>Immigration</span>
    </div>
    <div class="resource-box" (click)="navigate('5')">
      <div class="icon-container">
        <div class="icon-background miscellaneous  rotate-245"></div>
        <fa-icon [icon]="faEllipsis" class="icon outlined"></fa-icon>
      </div>
      <span>Miscellaneous</span>
    </div>
  </div>
  </div>
  <div class="parallax-container"  [@fadeIn]="fadeIn">
    <div class="parallax-image parallax-left" style="background-image: url('/assets/blue.png');"></div>
    <div class="parallax-image parallax-middle" style="background-image: url('/assets/red.png');"></div>
    <div class="parallax-image parallax-right" style="background-image: url('/assets/yellow.png');"></div>
  </div>  
</ng-template>
