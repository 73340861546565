<footer id="footer" class="footer">
  <div class="wrap">
    <div class="inner-wrap">
      <div class="footer-left">
        <div class="copyright">© SeasonalConnect, LLC</div>
        <nav class="conditions" *ngIf="portalURL">
          <a [href]="portalURL + '/TermsAccept'">Terms &amp; Conditions</a>
          <a [href]="portalURL + '/Privacy'">Privacy Policy</a>
          <a [href]="portalURL + '/Disclaimer'">Disclaimer</a>
          <a [href]="portalURL + '/RefundPolicy'">Refund Policy</a>
        </nav>
      </div>
      <div class="footer-right">
        <div class="social-icons">
          <a href="https://www.facebook.com/seasonalconnect" target="_blank">
            <mat-icon class="social-icon">facebook</mat-icon>
          </a>
          <a href="https://www.linkedin.com/company/seasonalconnect" target="_blank">
            <img src="assets/linkedin.svg" alt="LinkedIn" class="social-icon linkedin" />
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
