import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ApiService } from 'src/app/api.service';
import { SharedDataService } from 'src/app/shared-data-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  portalURL: string;
  constructor(
    public auth: AuthService,
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
  ) {
    
  }

  ngOnInit() {
    this.portalURL = this.sharedDataService.getData('portalURL');
    if(!this.portalURL)    {
      this.apiService.GetConfigurationParam("PortalURL").subscribe(res => { 
        this.portalURL = res; 
        this.sharedDataService.setData<string>('portalURL', res);
      });
    }
  }
}
