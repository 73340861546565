import { Component } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import {  Router } from '@angular/router';
import { filter } from 'rxjs';
import { faUsers, faUsersGear, faPeopleRoof, faEarthAmericas, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';




@Component({
  selector: 'resources-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  animations: [ trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class ResourcesIndexComponent {
  faUsers = faUsers;
  faUsersGear = faUsersGear;
  faPeopleRoof = faPeopleRoof;
  faEarthAmericas = faEarthAmericas;
  faEllipsis = faEllipsis;
  bounceIn: any;
  fadeIn: any;

  constructor(public auth: AuthService, private router: Router) {}

  navigate(topic: string): void {
    this.router.navigate(['/resources/resource-detail'], { queryParams: { topic } });
  }

  ngOnInit() {


    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),

        // Get the primary employer for the contact
      )
      .subscribe();
  }
}
